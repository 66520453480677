import axios from "@axios";
import i18n from "@/libs/i18n";
const { axiosRoutes: { calendar: path } } = require("/G_CONFIG");

export default {
	namespaced: true,
	state: {
		calendarOptions: [
			{
				color: "info",
				label: `${i18n.t("Diko GBT")}`, //"Diko Test",
			},
			{
				color: "primary",
				label: `${i18n.t("Diko Sessions")}`, //"Diko Egzersiz",
			},
			{
				color: "warning",
				label: `${i18n.t("Other Sessions")}`, //"Diğer Seanslar",
			},
			{
				color: "danger",
				label: `${i18n.t("Personal")}`, //"Kişisel",
			},
			// {
			// 	color: "success",
			// 	label: `${i18n.t("Other")}`, //"Diğer",
			// },
		],
		selectedCalendars: [
			i18n.t("Diko GBT"),
			i18n.t("Diko Sessions"),
			i18n.t("Other Sessions"), //"Diğer Seanslar",
			i18n.t("Personal"), //"Kişisel",
			// i18n.t("Other"), //"Diğer",
		],
	},
	getters: {},
	mutations: {
		SET_SELECTED_EVENTS(state, val) {
			state.selectedCalendars = val;
		},
	},
	actions: {
		fetchEvents(ctx, { calendars }) {
			// console.log("fetchEvents???");
			return new Promise((resolve, reject) => {
				axios
					.get(path.getAll, {
						params: {
							calendars: calendars ? calendars.join(",") : "",
						},
					})
					.then(response => {
						const date = new Date();
						response.data = response.data.map(item => {
							// console.log("ENTRY :: ", item);
							return {
								id: item._id,
								...item,
								start: new Date(
									parseInt(item.start) - date.getTimezoneOffset() * 60 * 1000,
								)
									.toISOString()
									.replace(/T/g, " ")
									.replace(/Z/g, "")
									.split(".")[0],
								end: new Date(
									parseInt(item.end) - date.getTimezoneOffset() * 60 * 1000,
								)
									.toISOString()
									.replace(/T/g, " ")
									.replace(/Z/g, "")
									.split(".")[0],
							};
						});
						// console.log("fetchEvents 22", response.data);
						resolve(response);
					})
					.catch(error => {
						console.log(error);
						reject(error);
					});
			});
		},
		addEvent(ctx, { event }) {
			// const date = new Date();
			event.start = new Date(
				event.start,
			).getTime(); /* + date.getTimezoneOffset() * 60 * 1000; */
			event.end = new Date(
				event.end,
			).getTime(); /* + date.getTimezoneOffset() * 60 * 1000; */
			return new Promise((resolve, reject) => {
				axios
					.post(path.new, { event })
					.then(response => {
						response.data.id = response.data._id;
						response.id = response._id;
						// console.log(response);
						resolve(response);
						// emit("refreshcalendar");
					})
					.catch(error => reject(error));
			});
		},
		updateEvent(ctx, { event }) {
			// const date = new Date();
			event.start = new Date(
				event.start,
			).getTime(); /* + date.getTimezoneOffset() * 60 * 1000; */
			event.end = new Date(
				event.end,
			).getTime(); /* + date.getTimezoneOffset() * 60 * 1000; */
			return new Promise((resolve, reject) => {
				axios
					.patch(`${path.edit}/${event.id}`, event)
					.then(response => {
						// console.log(response);
						response.data.id = response.data._id;
						response.id = response._id;
						// $emit("refreshcalendar");
						resolve(response);
					})
					.catch(error => reject(error));
			});
		},
		removeEvent(ctx, { id }) {
			return new Promise((resolve, reject) => {
				axios
					.delete(`${path.delete}/${id}`)
					.then(response => {
						resolve(response);
						// emit("refreshcalendar");
					})
					.catch(error => reject(error));
			});
		},
	},
};
