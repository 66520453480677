import { sliceEvents, createPlugin } from "@fullcalendar/core";
import i18n from "@/libs/i18n";

const calendarsColor = {};

calendarsColor[`${i18n.t("Diko GBT")}`] = "info";
calendarsColor[`${i18n.t("Diko Sessions")}`] = "primary";
calendarsColor[`${i18n.t("Other Sessions")}`] = "warning";
calendarsColor[`${i18n.t("Personal")}`] = "danger";

const CustomViewConfig = {
	classNames: ["fc-list fc-list-sticky fc-listMonth-view fc-view"],
	content: function(props) {
		// console.log("props ", props);
		let segs = sliceEvents(props, true); // allDay=true
		// console.log("segs");
		let segments = {};
		segs.map(seg => {
			const segDate = new Date(seg.range.start);
			const dateString = segDate.toISOString().split("T")[0];
			if (Array.isArray(segments[dateString])) {
				segments[dateString].push(seg);
			} else {
				segments[dateString] = [seg];
			}
		});
		// console.log("segments");
		// console.dir(segments);

		document
			.getElementById("sidebar-add-new-event")
			.setAttribute("aria-modal", "true");
		let html = `<div class="fc-scroller fc-scroller-liquid" style="overflow: hidden auto;">
    <table class="fc-list-table">
    <thead><tr><th scope="col">Time</th><th scope="col" aria-hidden="true"></th><th scope="col">Event</th></tr></thead>
    <tbody>
    `;
		for (const [key, value] of Object.entries(segments)) {
			const date = new Date(key);
			const day = date.toLocaleDateString(/*"tr-TR"*/ i18n.locale, {
				weekday: "long",
			});
			const month = date.toLocaleDateString(/*"tr-TR"*/ i18n.locale, {
				month: "long",
			});
			const dayNumber = date.toLocaleDateString(/*"tr-TR"*/ i18n.locale, {
				day: "numeric",
			});
			const year = date.toLocaleDateString(/*"tr-TR"*/ i18n.locale, {
				year: "numeric",
			});

			html += `<tr class="fc-list-day fc-day fc-day-mon fc-day-past" data-date="${key}"><th scope="colgroup" colspan="5" ><div class="fc-list-day-cushion fc-cell-shaded"><a class="fc-list-day-text" title="${dayNumber} ${month} ${year}" data-navlink="">${dayNumber} ${month} ${year}</a><a aria-hidden="true" class="fc-list-day-side-text" title="${dayNumber} ${month} ${year}" data-navlink="">${day}</a></div></th></tr>`;
			value.map(seg => {
				const segStart = new Date(seg.instance.range.start);
				const segEnd = new Date(seg.instance.range.end);
				html += `<tr class="fc-list-event  fc-event fc-event-start fc-event-end fc-event-past bg-light-${calendarsColor[
					seg.def.extendedProps.calendar
				]}" onclick="tryFunc()"><td class="fc-list-event-time">${segStart
					.toISOString()
					.split("T")[1]
					.slice(0, 5)} - ${segEnd
					.toISOString()
					.split("T")[1]
					.slice(
						0,
						5,
					)}</td><td aria-hidden="true" class="fc-list-event-graphic"><span class="fc-list-event-dot"></span></td><td headers="fc-dom-${parseInt(
					seg.def.sourceId,
				) - 1} fc-dom-${seg.def.sourceId}-${segStart
					.toISOString()
					.split("T")[0]
					.replaceAll(
						":",
						"-",
					)}" class="fc-list-event-title"><a tabindex="0">${seg.def
					.title}</a></td>
        <td style="width:10px" class="calendar-tooltip">`;
				if (seg.def.extendedProps.description) {
					html += `
            <svg xmlns="http://www.w3.org/2000/svg" width="14px" height="14px" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-edit"><path  d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path><path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path></svg>
            <span class="calendar-tooltip tooltip-text">${seg.def.extendedProps
							.description}</span>`;
				}
				html += `</td>
        <td style="width:10px;" class="calendar-tooltip">
        `;
				if (seg.def.extendedProps.guests.length > 0) {
					html += `<svg xmlns="http://www.w3.org/2000/svg" width="16px" height="16px" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" variant="primary" class="feather feather-user"><path  d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle  cx="12" cy="7" r="4"></circle></svg>`;
					html += `<span class="calendar-tooltip tooltip-text">`;
					seg.def.extendedProps.guests.map(guest => {
						html += `${guest.name}<br/>`;
					});
					html += `</span>`;
				}
				html += `</td>
        </tr>`;
			});
		}
		html += `</tbody></table></div>`;
		return { html: html };
	},
};

export default createPlugin({
	views: {
		custom: CustomViewConfig,
	},
});
