<template>
  <div>
    <b-sidebar id="sidebar-add-new-event" sidebar-class="sidebar-lg" :visible="isEventHandlerSidebarActive"
      bg-variant="white" shadow backdrop no-header right
      @change="(val) => $emit('update:is-event-handler-sidebar-active', val)">
      <template #default="{ hide }">
        <!-- Header -->
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5 class="mb-0">
            {{ $t("Event") }} {{ eventLocal.id ? $t("Update") : $t("Add") }}
          </h5>
          <div>
            <feather-icon v-if="eventLocal.id" icon="TrashIcon" class="cursor-pointer" @click="
                $emit('remove-event');
                $emit('refreshcalendar');
                hide();
              " />
            <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
          </div>
        </div>
        <!-- Body -->
        <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
          <!-- Form -->
          <b-form class="p-2" @submit.prevent="handleSubmit(onSubmit)" @reset.prevent="resetForm">
            <!-- Title -->
            <validation-provider #default="validationContext" :name="$t('Title')" rules="required">
              <b-form-group :label="$t('Topic')" label-for="event-title" label-cols="4">
                <b-form-input id="event-title" v-model="eventLocal.title" autofocus
                  :state="getValidationState(validationContext)" :placeholder="$t('Event Title')" />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] == "Başlık alanı gereklidir"
                        ? "Konu alanı boş bırakılamaz"
                        : validationContext.errors[0]
                    }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <!-- Calendar -->
            <validation-provider #default="validationContext" :name="$t('Calendar')" rules="required">
              <b-form-group :label="$t('Calendar Filter')" label-cols="4" label-for="calendar"
                :state="getValidationState(validationContext)">
                <v-select v-model="eventLocal.extendedProps.calendar" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="calendarOptions" label="label" :reduce="(calendar) => calendar.label" input-id="calendar">
                  <template #option="{ color, label }">
                    <div class="rounded-circle d-inline-block mr-50" :class="`bg-${color}`"
                      style="height: 10px; width: 10px" />
                    <span> {{ label }}</span>
                  </template>
                  <template #selected-option="{ color, label }">
                    <div class="rounded-circle d-inline-block mr-50" :class="`bg-${color}`"
                      style="height: 10px; width: 10px" />
                    <span> {{ label }}</span>
                  </template>
                </v-select>
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <!-- Start Date -->
            <validation-provider #default="validationContext" :name="$t('Start Date')" rules="required">
              <b-form-group :label="$t('Start Date')" label-for="start-date" label-cols="4" content-cols="8"
                :state="getValidationState(validationContext)">
                <flat-pickr v-model="eventLocal.start" class="form-control" id="idStartPicker" :config="{
                    enableTime: true,
                    dateFormat: 'Y-m-d H:i',
                    locale: Locale.tr,
                    time_24hr: true,
                    allowInput: true,
                    timepicker: true,
                  }" @input="handleStartDateChange($event)" />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <!-- End Date -->
            <validation-provider #default="validationContext" :name="$t('End Date')" rules="required">
              <b-form-group :label="$t('End Date')" label-cols="4" content-cols="8" label-for="end-date"
                :state="getValidationState(validationContext)">
                <flat-pickr v-model="eventLocal.end" class="form-control" :config="{
                    enableTime: true,
                    dateFormat: 'Y-m-d H:i',
                    locale: Locale.tr,
                    time_24hr: true,
                    minDate: eventLocal.start,
                    allowInput: true,
                  }" />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <!-- All Day -->
            <b-form-group :label="$t('Every Week')" label-for="repeat-bool" label-cols="4" content-cols="8"
              class="align-items-center flex-nowrap">
              <b-row class="flex-nowrap align-items-center">
                <b-form-checkbox id="repeat-bool" v-model="eventLocal.extendedProps.repeat" name="repeat-bool" switch
                  inline style="height: 100%" />
                <small v-show="eventLocal.extendedProps.groupId ? true : false">{{ $t("Repeated entries will be deleted if unselected")
                  }}</small>
              </b-row>
            </b-form-group>
            <b-form-group :label="$t('Update Repeats')" label-for="groupPatch-bool" label-cols="4" content-cols="8"
              class="align-items-center flex-nowrap" v-show="
                eventLocal.extendedProps.repeat && eventLocal.extendedProps.groupId
                  ? true
                  : false
              ">
              <b-row class="flex-nowrap">
                <b-form-checkbox id="groupPatch-bool" :model="eventLocal.extendedProps.groupPatch" name="groupPatch-bool"
                  @input="groupPatchSet" :checked="eventLocal.extendedProps.groupPatch" style="height: 100%" switch
                  inline />
                <small>{{ $t("Entry will be seperated from repeat group if unselected")
                  }}</small>
              </b-row>
            </b-form-group>
            <!-- v-show="eventLocal.extendedProps.groupId ? true : false" -->
            <!-- Event URL -->
            <!-- <validation-provider #default="validationContext" name="Event URL" rules="url">
              <b-form-group label="Event URL" label-for="event-url">
                <b-form-input
                  id="event-url"
                  v-model="eventLocal.eventUrl"
                  type="url"
                  :state="getValidationState(validationContext)"
                  placeholder="htttps://www.google.com"
                  trim
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider> -->
            <!-- Guests -->
            <b-form-group :label="$t('Subject')" label-for="add-guests" label-cols="3" content-cols="9">
              <v-select v-model="eventLocal.extendedProps.guests" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                multiple :close-on-select="false" :options="guestsOptions" label="name" input-id="add-guests">
                <template #option="{ name }">
                  <!-- <b-avatar size="sm" :src="avatar" /> -->
                  <span class="ml-50 align-middle"> {{ name }}</span>
                </template>
                <template #selected-option="{ name }">
                  <!-- <b-avatar size="sm" class="border border-white" :src="avatar" /> -->
                  <span class="ml-50 align-middle"> {{ name }}</span>
                </template>
              </v-select>
            </b-form-group>
            <!-- Location -->
            <!-- <b-form-group :label="$t('Location')" label-for="event-location" label-cols="3" content-cols="9">
              <b-form-input id="event-location" v-model="eventLocal.extendedProps.location"
                :placeholder="`${$t('Add Location')} (${$t('Optional')})`" />
            </b-form-group> -->
            <b-form-group>
              <b-form-input id="event-location" v-model="eventLocal.extendedProps.groupId" hidden />
            </b-form-group>
            <!-- Textarea -->
            <b-form-group :label="$t('Notes')" label-for="event-description" label-cols="3" content-cols="9">
              <b-form-textarea id="event-description" v-model="eventLocal.extendedProps.description" />
            </b-form-group>
            <!-- Form Actions -->
            <div class="d-flex mt-2">
              <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mr-2" type="submit">
                {{ eventLocal.id ? $t("Update") : $t("Add") }}
              </b-button>
              <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="reset" variant="outline-secondary">
                {{ $t("Reset") }}
              </b-button>
            </div>
          </b-form>
        </validation-observer>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BAvatar,
  BFormTextarea,
  BButton,
  BFormInvalidFeedback,
  BRow,
} from "bootstrap-vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import Locale from "flatpickr/dist/l10n/tr.js";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver, localize } from "vee-validate";
import { required, email, url } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import { ref, toRefs } from "@vue/composition-api";
import useCalendarEventHandler from "./useCalendarEventHandler";
import axios from "@axios";
import tr from "vee-validate/dist/locale/tr.json";

localize({
  tr,
});
localize("tr");

const {
  axiosRoutes: { subject: path },
} = require("/G_CONFIG");

export default {
  components: {
    BButton,
    BRow,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BFormTextarea,
    BAvatar,
    vSelect,
    flatPickr,
    ValidationProvider,
    BFormInvalidFeedback,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isEventHandlerSidebarActive",
    event: "update:is-event-handler-sidebar-active",
  },

  props: {
    isEventHandlerSidebarActive: {
      type: Boolean,
      required: true,
    },
    event: {
      type: Object,
      required: true,
    },
    clearEventData: {
      type: Function,
      required: true,
    },
  },
  methods: {
    handleStartDateChange(date) {
      // console.dir(this.eventLocal);
      if (
        (this.eventLocal.end === "" || this.eventLocal.end < this.eventLocal.start) &&
        date &&
        this.eventLocal.start
      ) {
        const selectedDate = new Date(date);
        this.eventLocal.end = new Date(selectedDate.getTime() + 3600000)
          .toISOString()
          .replace(/T/g, " ")
          .replace(/z/g);
      }
    },
    groupPatchSet(e) {
      this.eventLocal.extendedProps.groupPatch = e;
    },
  },
  data() {
    return {
      required,
      email,
      url,
      Locale,
    };
  },
  mounted() {
    const pickers = document.getElementsByClassName("flatpickr-time");

    for (let i = 0; i < pickers.length; i++) {
      // console.log(pickers[i].parentNode);
      let labelSpan = document.createElement("span");
      labelSpan.classList.add("flatpickr-time-separator");
      labelSpan.innerHTML = "Saat";
      pickers[i].parentNode.insertBefore(labelSpan, pickers[i]);
    }
  },
  setup(props, { emit }) {
    /*
     ? This is handled quite differently in SFC due to deadlock of `useFormValidation` and this composition function.
     ? If we don't handle it the way it is being handled then either of two composition function used by this SFC get undefined as one of it's argument.
     * The Trick:

     * We created reactive property `clearFormData` and set to null so we can get `resetEventLocal` from `useCalendarEventHandler` composition function.
     * Once we get `resetEventLocal` function which is required by `useFormValidation` we will pass it to `useFormValidation` and in return we will get `clearForm` function which shall be original value of `clearFormData`.
     * Later we just assign `clearForm` to `clearFormData` and can resolve the deadlock. 😎

     ? Behind The Scene
     ? When we passed it to `useCalendarEventHandler` for first time it will be null but right after it we are getting correct value (which is `clearForm`) and assigning that correct value.
     ? As `clearFormData` is reactive it is being changed from `null` to corrent value and thanks to reactivity it is also update in `useCalendarEventHandler` composition function and it is getting correct value in second time and can work w/o any issues.
    */
    const clearFormData = ref(null);

    const {
      eventLocal,
      resetEventLocal,
      calendarOptions,

      // UI
      onSubmit,
      guestsOptions,
    } = useCalendarEventHandler(toRefs(props), clearFormData, emit);

    const { refFormObserver, getValidationState, resetForm, clearForm } = formValidation(
      resetEventLocal,
      props.clearEventData
    );

    clearFormData.value = clearForm;
    /* console.log(guestsOptions)
    console.log('eventlocal')
    console.log(eventLocal) */
    return {
      // Add New Event
      eventLocal,
      calendarOptions,
      onSubmit,
      guestsOptions,

      // Form Validation
      resetForm,
      refFormObserver,
      getValidationState,
    };
  },
  async created() {
    await axios
      .get(path.getAll, {
        params: {
          perPage: 100000000,
          page: 1,
        },
      })
      .then((response) => {
        this.guestsOptions = response.data.subjects.map((item) => ({
          avatar: item.avatar || "-",
          name: item.name,
          id: item._id,
        }));
      });
    /* console.log(this.guestsOptions) */
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";

// #sidebar-add-new-event {
//   height: auto !important;
// }
</style>
